.tui-editor-defaultUI {
  border: none;
  border-radius: 3px;
}
.te-mode-switch-section, .tui-editor-defaultUI-toolbar, .te-editor-section, .te-toolbar-section, .te-mode-switch ,.te-switch-button, .te-ww-container  {
  border-radius: 3px;

}
.customInputBtn{
  display: flex;
  justify-content: center;
  align-items: center;  /* margin:0px !important; */
  padding:0px !important;
}
.customIcon{
  fill:#333 !important;
  width:17px !important;
  height:17px !important;
}

.tui-editor-contents-placeholder::before {
  color: #C3C3C3;
}

.ql-editor {
  height: 400px !important;
}

.tox-dialog__body-nav {
  display: none !important;
}