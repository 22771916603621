:focus-visible {
  outline: rgb(0, 93, 170, 0.1) outset 1px;
  outline-offset: 3px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*
{
    font-family:  Roboto, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: #FF9000 !important;
  color: #fff;
}

.blog-comment:nth-of-type(1) {
  border-top: 1px solid #E2E2E2;
}

.blog-comment:nth-of-type(1)::before {
  content: '';
  position: absolute;
  left: 50px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  z-index: 10
}

.blog-comment:nth-of-type(1)::after {
  content: '';
  position: absolute;
  left: 49px;
  top: -11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 11px 11px;
  border-color: transparent transparent #E2E2E2 transparent;
  z-index: 9
}

